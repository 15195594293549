import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    list: null,
    categoryList: null,
    errorMessage: null,
    completed: false
}

export const getItemList = createAsyncThunk(
    'get/itemList',
    async () => {
        const response = await restApi.get('menuItems');
        return response.data;
    }
)

export const getCategoryList = createAsyncThunk(
    'get/categoryList',
    async () => {
        const response = await restApi.get('menuItems/category');
        return response.data;
    }
)

export const postItemList = createAsyncThunk(
    'post/itemList',
    async (body, { dispatch }) => {
        body.uuid = null;
        const response = await restApi.post('menuItems', body);
        dispatch(getItemList());
        return response.data;
    }
)

export const patchItemList = createAsyncThunk(
    'patch/itemList',
    async (body, { dispatch }) => {
        const response = await restApi.patch('menuItems', body);
        dispatch(getItemList());
        return response.data;
    }
)

export const deleteItemList = createAsyncThunk(
    'delete/itemList',
    async (body, { dispatch }) => {
        const response = await restApi.delete('menuItems', { data: body });
        dispatch(getItemList());
        return response.data;
    }
)

export const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        resetErrorMessage: (state) => {
            state.errorMessage = null;
        },
        resetCompleted: (state) => {
            state.completed = false;
        }
    },
    extraReducers: {
        [getItemList.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [getItemList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
        [getCategoryList.fulfilled]: (state, action) => {
            state.categoryList = action.payload;
        },
        [getCategoryList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
        [postItemList.pending]: (state, action) => {
            state.completed = false;
        },
        [postItemList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [postItemList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
        [patchItemList.pending]: (state, action) => {
            state.completed = false;
        },
        [patchItemList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [patchItemList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
        [deleteItemList.pending]: (state, action) => {
            state.completed = false;
        },
        [deleteItemList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [deleteItemList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
    }
});

export const { resetErrorMessage, resetCompleted } = itemSlice.actions
export default itemSlice.reducer