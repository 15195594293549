import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import Toolbar from './Toolbar/Toolbar';
import Drawer from './Drawer/Drawer';

const Root = styled('div')(({ theme }) => ({
    display: "flex",
    width: '100%',
    height: '100%',
    position: 'fixed'
}))

const Layout = props => {
    const isAuthenticated = useSelector(state => state.auth.token == null ? false : true);
    return (
        <Root >
            {isAuthenticated ? <Drawer /> : null}
            <Toolbar main={props.children} />
        </Root>
    );
};

export default Layout;