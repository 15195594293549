import React, { Suspense, useEffect } from 'react';
import { Route, Navigate , Routes } from 'react-router-dom';
import {withRouter} from './hooks/WithRouter';
import { useSelector, useDispatch } from 'react-redux';
import Layout from './Layout/Layout';
import { tryAutoSignIn } from './store/AuthSlice';

const Login = React.lazy(() => {
  return import('./containers/Login/Login');
});

const SignUp = React.lazy(() => {
  return import('./containers/SignUp/SignUp');
})

const Home = React.lazy(() => {
  return import('./containers/Home/Home');
});

const NewOrder = React.lazy(() => {
  return import('./containers/NewOrder/NewOrder');
});

const Pickups = React.lazy(() => {
  return import('./containers/Pickups/Pickups');
});

const ItemList = React.lazy(() => {
  return import('./containers/ItemList/ItemList');
});

const CustomerList = React.lazy(() => {
  return import('./containers/CustomerList/CustomerList');
});

const ChangeOrder = React.lazy(() => {
  return import('./containers/ChangeOrder/ChangeOrder');
});

const OrderHistory = React.lazy(() => {
  return import('./containers/OrderHistory/OrderHistory');
});

const Analytics = React.lazy(() => {
  return import('./containers/Analytics/Analytics');
});

const AccountSettings = React.lazy(() => {
  return import('./containers/AccountSettings/AccountSettings');
});

const Downloads = React.lazy(() => {
  return import('./containers/Downloads/Downloads');
});

const Subscription = React.lazy(() => {
  return import('./containers/Subscription/Subscription');
});

const SubCanceled = React.lazy(() => {
  return import('./containers/Subscription/SubCanceled');
});

const SubSucceed = React.lazy(() => {
  return import('./containers/Subscription/SubSucceed');
});

const PasswordReset = React.lazy(() => {
  return import('./containers/PasswordReset/PasswordReset');
});

const PasswordChange = React.lazy(() => {
  return import('./containers/PasswordChange/Passwordchange');
});

const ManageEmployees = React.lazy(() => {
  return import('./containers/ManageEmployees/ManageEmployees');
});

const App = () => {
  const isAuthenticated = localStorage.getItem('token') == null ? false : true;
  const routeGaurd = useSelector(state => state.auth.token == null ? false : true);

  const dispatch = useDispatch();

  const onTryAutoSignIn = () => dispatch(tryAutoSignIn());

  useEffect(() => {
    onTryAutoSignIn();
  }, [onTryAutoSignIn]);

  let routes = (
    <Routes>
      <Route path="/login" element={ <Login />} />
      <Route path="/sign-up" element={ <SignUp/>} />
      <Route path="/password/reset" element={ <PasswordReset  />} />
      <Route path="/password/change/*" element={ <PasswordChange  />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );

  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route path="/subscription/succeed" element={ <SubSucceed  />} />
        <Route path="/subscription/canceled" element={ <SubCanceled  />} />
        <Route path="/"  element={ <Home  />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  if (isAuthenticated && routeGaurd) {
    routes = (
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/item-list" element={ <ItemList  />} />
        <Route path="/customer-list" element={ <CustomerList  />} />
        <Route path="/new-order" element={ <NewOrder  />} />
        <Route path="/pickups" element={ <Pickups  />} />
        <Route path="/change-order" element={ <ChangeOrder  />} />
        <Route path="/order-history" element={ <OrderHistory  />} />
        <Route path="/analytics" element={ <Analytics  />} />
        <Route path="/account-settings" element={ <AccountSettings  />} />
        <Route path="/downloads" element={ <Downloads  />} />
        <Route path="/sign-up" element={ <SignUp  />} />
        <Route path="/subscription" element={ <Subscription  />} />
        <Route path="/subscription/succeed" element={ <SubSucceed  />} />
        <Route path="/subscription/canceled" element={ <SubCanceled  />} />
        <Route path="/manage-employees" element={ <ManageEmployees  />} />
        <Route path="/"  element={ <Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <React.Fragment>
      <Layout>
        <Suspense fallback={<p>...loading</p>}>{routes}</Suspense>
      </Layout>
    </React.Fragment>
  );
}

export default withRouter(App);
