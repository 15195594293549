import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

export const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))

export const Header = styled('div')(({ theme }) => ({
    display: "flex",
    flexWrap: 'nowrap',
    justifyContent: "center"
}))


export const Main = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '95vh',
    overflowY: 'auto'
}))

export const AppbarItems = styled('div')(({ theme }) => ({
    display: "flex",
    flexGrow: 1.25,
    justifyContent: "flex-end",
    textDecoration: "none",
}))

export const AppBar = styled(MuiAppBar)(
    ({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1,
        maxWidth: "100%",
        position: "static",
        backgroundColor: "white",
        color: "black",
        boxShadow: "none !important ",
        fontSize: theme.spacing(3),
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    }),
);