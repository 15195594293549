import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    status: false,
    errorMessage: null
}

export const getPrinterAppStatus = createAsyncThunk(
    'get/printerAppStatus',
    async () => {
        const response = await restApi.get('printer/status')
        return response.data
    }
)

export const postPrintReceipt = createAsyncThunk(
    'post/printerReceipt',
    async (orderInfo, { dispatch }) => {
        const response = await restApi.post('printer/receipt/broadcast?orderNumber=' + orderInfo.order_number + '&amount=' + orderInfo.amount)
        return response.data
    }
)

export const postPrintTag = createAsyncThunk(
    'post/printerReceipt',
    async (orderInfo, { dispatch }) => {
        const response = await restApi.post('printer/tag/broadcast?orderNumber=' + orderInfo.order_number + '&amount=' + orderInfo.amount)
        return response.data
    }
)

export const printerAppSlice = createSlice({
    name: 'printerApp',
    initialState,
    reducers: {},
    extraReducers: {
        [getPrinterAppStatus.fulfilled]: (state, action) => {
            state.status = action.payload.connected;
        },
        [getPrinterAppStatus.rejected]: (state, action) => {
            state.errorMessage = action.payload;
        },
        [postPrintTag.rejected]: (state, action) => {
            state.errorMessage = action.payload;
        },
        [postPrintReceipt.rejected]: (state, action) => {
            state.errorMessage = action.payload;
        },
    }
});

export default printerAppSlice.reducer