import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    list: [],
    completed: false,
    fulfilled: false,
    historyList: [],
    orderListCount: 0,
    errorMessage: null,
}

export const getCustomerOrders = createAsyncThunk(
    'get/customerOrders',
    async (status) => {
        let url = 'customerOrders';
        if (status !== null && status !== undefined)
            url = 'customerOrders?status=' + status
        const response = await restApi.get(url)
        return response.data
    }
)

export const postCustomerOrder = createAsyncThunk(
    'post/customerOrder',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.post('customerOrder', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const patchCustomerOrder = createAsyncThunk(
    'patch/customerOrder',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.patch('customerOrder', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteCustomerOrder = createAsyncThunk(
    'delete/customerOrder',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.delete('customerOrder', { data: body })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrder: (state, action) => {
            state.orderList = [];
            state.errorMessage = null;
        },
        resetCompleted: (state, action) =>{
            state.completed = false;
        }
    },
    extraReducers: {
        [getCustomerOrders.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [getCustomerOrders.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error];
            else
                state.errorMessage = [action.error];
        },
        [postCustomerOrder.pending]: (state, action) => {
            state.completed = false;
        },
        [postCustomerOrder.fulfilled]: (state, action) => {
            state.completed = true;
            state.list = [];
            state.errorMessage = null;
        },
        [postCustomerOrder.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error];
            else
                state.errorMessage = [action.error];
        },
        [patchCustomerOrder.pending]: (state, action) => {
            state.completed = false;
        },
        [patchCustomerOrder.fulfilled]: (state, action) => {
            state.completed = true;
            state.list = [];
            state.errorMessage = null;
        },
        [patchCustomerOrder.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else {
                state.errorMessage = [action.error.message];
            }
        },
        [deleteCustomerOrder.pending]: (state, action) => {
            state.completed = false;
        },
        [deleteCustomerOrder.fulfilled]: (state, action) => {
            state.completed = true;
        },
        [deleteCustomerOrder.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else {
                state.errorMessage = [action.error.message];
            }
        },
    }
});

export const { resetOrder, resetCompleted } = orderSlice.actions

export default orderSlice.reducer