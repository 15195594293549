import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    url: null,
    completed: false,
    errorMessage: null,
}

export const postCreatePaymentIntent = createAsyncThunk(
    'post/createPaymentIntent',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.post('create-checkout-session', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: {
        [postCreatePaymentIntent.pending]: (state, action) => {
            state.completed = false;
        },
        [postCreatePaymentIntent.fulfilled]: (state, action) => {
            state.url = action.payload.url
            state.completed = true;
            state.errorMessage = null;
        },
        [postCreatePaymentIntent.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error];
            else
                state.errorMessage = [action.error];
        },
    }
});

export default paymentSlice.reducer