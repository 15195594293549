import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    yearlySales: [],
    monthlySales: [],
    dailySales: [],
    daySalesTotal: 0,
    errorMessage: null,
}

export const postAnalyticsSalesMonthly = createAsyncThunk(
    'post/analytics/sales/monthly',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.post('analytics/sales/monthly', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const postAnalyticsSalesDaily = createAsyncThunk(
    'post/analytics/sales/daily',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.post('analytics/sales/daily', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const postAnalyticsSalesTotalDay = createAsyncThunk(
    'post/analytics/sales/total',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.post('analytics/sales/total', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const analyticsSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetFulfilled: (state, action) => {
            state.fulfilled = false;
        }
    },
    extraReducers: {
        [postAnalyticsSalesMonthly.fulfilled]: (state, action) => {
            state.monthlySales = action.payload;
        },
        [postAnalyticsSalesMonthly.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error];
            else
                state.errorMessage = [action.error];
        },
        [postAnalyticsSalesDaily.fulfilled]: (state, action) => {
            state.dailySales = action.payload;
        },
        [postAnalyticsSalesTotalDay.fulfilled]: (state, action) => {
            state.daySalesTotal = action.payload.total;
        },
    }
});

export const { resetFulfilled } = analyticsSlice.actions

export default analyticsSlice.reducer