import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    employees: [],
    completed: false,
    errorMessage: null,
}

export const patchChangePassword = createAsyncThunk(
    'patch/password',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.patch('employee/password', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const patchPasswordReset = createAsyncThunk(
    'patch/password/reset',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.patch('employee/password/reset', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const postEmployee = createAsyncThunk(
    'post/employee',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.post('employee', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const getEmployees = createAsyncThunk(
    'get/employees',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.get('employees')
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const patchEmployee = createAsyncThunk(
    'patch/employee',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.patch('employee', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const employeeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        resetCompleted: (state) => {
            state.completed = false;
        }
    },
    extraReducers: {
        [patchChangePassword.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.completed = true;
        },
        [patchChangePassword.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [patchPasswordReset.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [postEmployee.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [postEmployee.rejected]: (state, action) => {
            state.completed = true;
            state.errorMessage = action.payload;
        },
        [getEmployees.fulfilled]: (state, action) => {
            state.completed = true;
            state.employees = action.payload;
            state.errorMessage = null;
        },
        [getEmployees.rejected]: (state, action) => {
            state.completed = true;
            state.errorMessage = action.payload;
        },
        [patchEmployee.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [patchEmployee.rejected]: (state, action) => {
            state.completed = true;
            state.errorMessage = action.payload;
        },
    }
});

export const {resetCompleted } = employeeSlice.actions
export default employeeSlice.reducer