import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    completed: false,
    errorMessage: null
}

export const postCompany = createAsyncThunk(
    'get/store',
    async () => {
        const response = await restApi.get('company')
        return response.data
    }
)

export const signUpSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setCompleted: (state, action) => {
            state.completed = action.payload;
        }
    },
    extraReducers: {
        [postCompany.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [postCompany.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.completed = true;
        },
    }
});

export const { setCompleted } = signUpSlice.actions
export default signUpSlice.reducer