import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { List, Divider, ListItemIcon, ListItemText, Tooltip, ListItemButton } from '@mui/material';
import { Group, CardMembership, Settings, Add, ShowChart, FormatListNumbered, Edit, ChevronRight, ChevronLeft, History, DoneOutline, GetApp, ManageAccounts } from '@mui/icons-material';
import LogoAsset from '../../assets/logo.png';
import { Root, Logo, Drawer } from './DrawerStyled';

const AppDrawer = () => {
    const [open, setOpen] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);

    const navigate = useNavigate();

    const iconColor = "springgreen";

    useEffect(() => {
        setRedirectHome(false);
    }, [redirectHome]);

    return (
        <Root>
            <Drawer
                variant="permanent"
                open={open}
            >
                <Logo>
                    <img src={LogoAsset} width="60" height="62" alt="posper logo" onClick={() => setRedirectHome(prevState => !prevState)} />
                </Logo>
                <List sx={{ display: 'flex', flexDirection: 'column', overflow: 'none', }}>
                    <ListItemButton key={"New Order Id"} component={Link} to="/new-order">
                        <Tooltip title="New Order" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/new-order" ? iconColor : "white", pl: '0.39rem' }}>{<Add fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"New order"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Pick Ups Id"} component={Link} to="/pickups">
                        <Tooltip title="Pick Ups" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/pickups" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<DoneOutline fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Pick Ups"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Change Order Id"} component={Link} to="/change-order">
                        <Tooltip title="Change Orders" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/change-order" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<Edit fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Change Orders"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Order History Id"} component={Link} to="/order-history">
                        <Tooltip title="Order History" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/order-history" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<History fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Order History"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Analytics Id"} component={Link} to="/analytics">
                        <Tooltip title="Analytics" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/analytics" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<ShowChart fontSize='large' />}</ListItemIcon >
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Analytics"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Item List Id"} component={Link} to="/item-list">
                        <Tooltip title="Item List" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/item-list" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<FormatListNumbered fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Item List"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Customers List id"} component={Link} to="/customer-list">
                        <Tooltip title="Customers List" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/customer-list" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<Group fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Customers List"} /> : null}
                    </ListItemButton>
                    <Divider sx={{ borderColor: 'white', pt: 3 }} />
                    <ListItemButton key={"managed employees Id"}  component={Link} to="/manage-employees">
                        <Tooltip title="Manage Employees" placement="right">
                            <ListItemIcon sx={{color: window.location.pathname === "/manage-employees" ? iconColor : 'white', pt: 0.1, pl: '0.39rem' }} >{<ManageAccounts fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color:'white' }} primary={"Manage Employees"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Pay Subscription Id"} component={Link} to="/subscription">
                        <Tooltip title="Pay Subscription" placement="right">
                            <ListItemIcon sx={{color: window.location.pathname === "/subscription" ? iconColor : 'white', pt: 0.1, pl: '0.39rem' }} >{<CardMembership fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Pay Subscription"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Account Settings Id"} component={Link} to="/account-settings">
                        <Tooltip title="Account Settings" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/account-settings" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<Settings fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Account Settings"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Download Id"} component={Link} to="/downloads">
                        <Tooltip title="Downloads" placement="right">
                            <ListItemIcon sx={{ color: window.location.pathname === "/downloads" ? iconColor : "white", pt: 0.1, pl: '0.39rem' }}>{<GetApp fontSize='large' />}</ListItemIcon>
                        </Tooltip>
                        {open ? <ListItemText sx={{ color: 'white' }} primary={"Downloads"} /> : null}
                    </ListItemButton>
                    <ListItemButton key={"Hide Side Menu Id"}>
                        <ListItemIcon sx={{ color: 'white', pt: 0.1, pl: '0.39rem' }} onClick={event => setOpen(prevState => !prevState)}>
                            {open ? <ChevronLeft fontSize='large' /> : <ChevronRight fontSize='large' />}
                        </ListItemIcon>
                        {open ? <ListItemText sx={{ color: 'white', pt: 0.1 }} onClick={event => setOpen(prevState => !prevState)} primary={"Hide Side Menu"} /> : null}
                    </ListItemButton>
                </List>
                {redirectHome ? navigate("/") : null}
            </Drawer>
        </Root >
    );
}

export default AppDrawer;