import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { logout } from '../../store/AuthSlice'
import { Toolbar, Typography, Button } from '@mui/material';
import { Root, Header, Main, AppBar, AppbarItems } from './ToolbarStyled';

const AppToolbar = (props) => {
    const isAuthenticated = useSelector(state => state.auth.token == null ? false : true);
    const companyName = useSelector(state => state.auth.companyName);
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(logout());
    }

    return (
        <Root>
            <Header >
                <AppBar>
                    <Toolbar>
                        <Typography sx={{ textDecoration: "none", color: "unset" }} variant="h4" noWrap component={Link} to="/">
                            {companyName ? "Welcome " + companyName :
                                <div>
                                    <span style={{ color: "#f50057" }}>Posper </span>a simple dry cleaning order management tool
                                </div>}
                        </Typography>
                        <AppbarItems>
                            {isAuthenticated ? <Button color="inherit" component={Link} to="/login" onClick={logoutHandler} style={{ textTransform: "none" }}>
                                <Typography noWrap variant="h5">
                                    Logout
                                </Typography>
                            </Button> : null}
                        </AppbarItems>
                    </Toolbar>
                </AppBar>
            </Header>
            <Main>
                {props.main}
            </Main>
        </Root >
    );
}

export default AppToolbar;