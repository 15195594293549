import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    items: [],
    order: {},
    customer: {}
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        updateCheckoutItems: (state, action) => {
            state.items = action.payload;
        },
        resetCheckOutItems: (state) => {
            state.items = [];
        },
        updateCheckoutOrder: (state, action) => {
            state.order = action.payload;

        },
        resetCheckoutOrder: (state) => {
            state.order = {};
        },
        updateCheckoutCustomer: (state, action) => {
            state.customer = action.payload;

        },
        resetCheckoutCustomer: (state) => {
            state.customer = {};
        },
        resetCheckout: (state) => {
            state.items = [];
            state.order = {};
            state.customer = {};
        }
    }
});

export const { updateCheckoutItems, resetCheckOutItems, updateCheckoutOrder, resetCheckoutOrder, updateCheckoutCustomer, resetCheckoutCustomer, resetCheckout } = checkoutSlice.actions
export default checkoutSlice.reducer