import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    license: null,
    errorMessage: null,
    taxRatePercentage: '',
    completed: false,
    stores : [],
    employeeStore: {}
}

export const getStore = createAsyncThunk(
    'get/store',
    async () => {
        const response = await restApi.get('store');
        return response.data;
    }
)

export const patchStore = createAsyncThunk(
    'patch/printerSetting',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const response = await restApi.patch('store', body);
            dispatch(getStore());
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const getStores = createAsyncThunk(
    'get/stores',
    async () => {
        const response = await restApi.get('stores');
        return response.data;
    }
)

export const getStoreEmployee = createAsyncThunk(
    'get/store/employee',
    async (param) => {
        const response = await restApi.get('store/'+ param.storeUuid+ '?employee=' + param.employeeUuid);
        return response.data;
    }
)

export const putStoreEmployee = createAsyncThunk(
    'put/store/employee',
    async (body) => {
        const response = await restApi.put('store/employee', body);
        return response.data;
    }
)

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        resetCompleted: (state) => {
            state.completed = false;
        }
    },
    extraReducers: {
        [getStore.fulfilled]: (state, action) => {
            const { license, tax_rate_percentage } = action.payload;
            state.license = license;
            state.taxRatePercentage = tax_rate_percentage;
        },
        [getStore.rejected]: (state, action) => {
            state.errorMessage = action.payload;
        },
        [patchStore.fulfilled]: (state, action) => {
            state.errorMessage = null;
            state.completed = true;
        },
        [patchStore.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.completed = true;
        },
        [getStores.fulfilled]: (state, action) => {
            state.stores = action.payload
            state.errorMessage = null;
            state.completed = true;
        },
        [getStoreEmployee.fulfilled]: (state, action) => {
            state.employeeStore = action.payload;
            state.errorMessage = null;
            state.completed = true;
        },
        [getStoreEmployee.rejected]: (state, action) => {
            state.employeeStore = null;
            state.errorMessage = action.payload;
            state.completed = true;
        },
        [putStoreEmployee.rejected]: (state, action) => {
            state.employeeStore = null;
            state.completed = true;
        },
    }
});

export const { resetCompleted } = storeSlice.actions
export default storeSlice.reducer