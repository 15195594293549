import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    receiptPrinterEnabled: false,
    tagPrinterEnabled: false,
    errorMessage: null,
    tagPrinterType: '',
    receiptPrinterType: '',
    tagPrinterConnection: '',
    receiptPrinterConnection: '',
    address: '',
    addressSecond: '',
    bottom: '',
    bottomSecond: '',
    bottomThird: '',
    title: '',
    titleSecond: '',
    phoneNumber: '',
    completed: false
}

export const getPrinterSetting = createAsyncThunk(
    'get/printerSetting',
    async () => {
        const response = await restApi.get('printer/setting')
        return response.data
    }
)

export const patchPrinterSetting = createAsyncThunk(
    'patch/printerSetting',
    async (body, { rejectWithValue }) => {
        try {
            const response = await restApi.patch('printer/setting', body)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const printerSettingSlice = createSlice({
    name: 'printerSetting',
    initialState,
    reducers: {
        resetCompleted: (state) => {
            state.completed = false;
        }
    },
    extraReducers: {
        [getPrinterSetting.fulfilled]: (state, action) => {
            const { receipt_printer_enable, tag_printer_enable, tag_printer_type,
                receipt_printer_type, tag_printer_connection, receipt_printer_connection,
                title_first_line, title_second_line, phone_number, address_first_line, address_second_line,
                bottom_first_line, bottom_second_line, bottom_third_line } = action.payload
            state.receiptPrinterEnabled = receipt_printer_enable;
            state.tagPrinterEnabled = tag_printer_enable;
            state.tagPrinterType = tag_printer_type;
            state.receiptPrinterType = receipt_printer_type;
            state.tagPrinterConnection = tag_printer_connection;
            state.receiptPrinterConnection = receipt_printer_connection;
            state.title = title_first_line;
            state.titleSecond = title_second_line;
            state.phoneNumber = phone_number;
            state.address = address_first_line;
            state.addressSecond = address_second_line;
            state.bottom = bottom_first_line;
            state.bottomSecond = bottom_second_line;
            state.bottomThird = bottom_third_line;
        },
        [getPrinterSetting.rejected]: (state, action) => {
            state.errorMessage = action.payload;
        },
        [patchPrinterSetting.fulfilled]: (state, action) => {
            const { receipt_printer_enable, tag_printer_enable, tag_printer_type,
                receipt_printer_type, tag_printer_connection, receipt_printer_connection,
                title_first_line, title_second_line, phone_number, address_first_line, address_second_line,
                bottom_first_line, bottom_second_line, bottom_third_line } = action.payload
            state.receiptPrinterEnabled = receipt_printer_enable;
            state.tagPrinterEnabled = tag_printer_enable;
            state.tagPrinterType = tag_printer_type;
            state.receiptPrinterType = receipt_printer_type;
            state.tagPrinterConnection = tag_printer_connection;
            state.receiptPrinterConnection = receipt_printer_connection;
            state.title = title_first_line;
            state.titleSecond = title_second_line;
            state.phoneNumber = phone_number;
            state.address = address_first_line;
            state.addressSecond = address_second_line;
            state.bottom = bottom_first_line;
            state.bottomSecond = bottom_second_line;
            state.bottomThird = bottom_third_line;
            state.completed = true;
            state.errorMessage = null;
        },
        [patchPrinterSetting.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.completed = true;
        },
    }
});

export const { resetCompleted } = printerSettingSlice.actions
export default printerSettingSlice.reducer