import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import restApi from '../apis/apis';

const initialState = {
    token: null,
    stores: null,
    companyName: null,
    expirationTime: null,
    errorMessage: null,
    error: false,
    authRedirectPath: '/',
}

export const postLogin = createAsyncThunk(
    'posts/login',
    async (body, { dispatch }) => {
        const response = await restApi.post('login', body);
        dispatch(checkAuthTimeOut(response.data.expiration_time));
        return response.data
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state) => {
            state.companyName = localStorage.getItem('companyName');
            state.token = localStorage.getItem('token');
            state.expirationTime = localStorage.getItem('expirationDate');
        },
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('companyName');
            state.companyName = null;
            state.token = null;
            state.expirationTime = null;
        },
    },
    extraReducers: {
        [postLogin.pending]: (state) => {
            state.errorMessage = null;
            state.error = false;
        },
        [postLogin.fulfilled]: (state, action) => {
            const { jwt, company_name, stores, expiration_time } = action.payload
            localStorage.setItem('token', jwt);
            localStorage.setItem('expirationDate', new Date(Date.now() + Number(expiration_time)));
            localStorage.setItem('companyName', company_name);
            state.token = jwt;
            state.companyName = company_name;
            state.stores = stores;
            state.expirationTime = expiration_time;
        },
        [postLogin.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
        },
    }
});

export const checkAuthTimeOut = expirationTime => dispatch => {
    setTimeout(() => {
        dispatch(logout());
    }, expirationTime);
};

export const tryAutoSignIn = () => dispatch => {
    const token = localStorage.getItem('token');
    if (!token)
        dispatch(logout());
    else {
        if (new Date(localStorage.getItem('expirationDate')) <= new Date())
            dispatch(logout());
        else
            dispatch(setAuthState());
    }
};

export const { setAuthState, logout } = authSlice.actions

export default authSlice.reducer