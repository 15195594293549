import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = '14rem';

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#233044",
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMixin = (theme) => ({
    overflowX: 'hidden',
    backgroundColor: "#233044",
    width: '5rem',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const Root = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: 'column',
    flexWrap: 'nowrap',
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
}))

export const Logo = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(4),
    cursor: "pointer",
}))

