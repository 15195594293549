import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './store/AuthSlice';
import checkoutReducer from './store/CheckoutSlice';
import orderReducer from './store/OrderSlice';
import itemReducer from './store/ItemSlice';
import customerReducer from './store/CustomerSlice';
import printerAppReducer from './store/PrinterAppSlice';
import storeReducer from './store/StoreSlice';
import printerSettingReducer from './store/PrinterSettingSlice';
import analyticsReducer from './store/AnalyticsSlice';
import employeeReducer from './store/EmployeeSlice';
import signUpReducer from './store/SignUpSlice';
import paymentReducer from './store/PaymentSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        checkout: checkoutReducer,
        order: orderReducer,
        item: itemReducer,
        customer: customerReducer,
        printerApp: printerAppReducer,
        store: storeReducer,
        printerSetting: printerSettingReducer,
        analytics: analyticsReducer,
        employee: employeeReducer,
        signUp: signUpReducer,
        payment: paymentReducer
    },
});

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
