import axios from 'axios';

const restApi = axios.create({
    baseURL: 'https://posper.ca/api/v1/',
    // baseURL: '/api/v1/',
    headers: {
        post: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
});

restApi.interceptors.request.use(request => {
    const token = localStorage.getItem('token');
    request.headers['Authorization'] = token;
    if (request.url === 'login' || request.url === 'employee/password/reset')
        delete request.headers['Authorization'];

    return request;
}, error => {
    return Promise.reject(error);
});

restApi.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

export default restApi;