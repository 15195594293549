import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updateCheckoutCustomer } from './CheckoutSlice';

import restApi from '../apis/apis';

const initialState = {
    list: {},
    errorMessage: null,
    completed: false
}

export const getCustomerList = createAsyncThunk(
    'get/customerList',
    async () => {
        const response = await restApi.get('customers')
        return response.data
    }
)

export const postCustomerList = createAsyncThunk(
    'post/customerList',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await restApi.post('customers', body)
            dispatch(updateCheckoutCustomer(response.data[0]))
            dispatch(getCustomerList());    
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const patchCustomerList = createAsyncThunk(
    'patch/customerList',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await restApi.patch('customers', body)
            dispatch(getCustomerList());
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteCustomerList = createAsyncThunk(
    'delete/customerList',
    async (body, { dispatch }) => {
        const response = await restApi.delete('customers', { data: body });
        dispatch(getCustomerList());
        return response.data
    }
)

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        resetErrorMessage: (state) => {
            state.errorMessage = null;
        },
        resetCompleted: (state) => {
            state.completed = false;
        }
    },
    extraReducers: {
        [getCustomerList.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [getCustomerList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        },
        [postCustomerList.pending]: (state, action) => {
            state.completed = false;
        },
        [postCustomerList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [postCustomerList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, getErrorMessage(action.payload)];
            else
                state.errorMessage = [getErrorMessage(action.payload)];
        },
        [patchCustomerList.pending]: (state, action) => {
            state.completed = false;
        },
        [patchCustomerList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [patchCustomerList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, getErrorMessage(action.payload)];
            else
                state.errorMessage = [getErrorMessage(action.payload)];
        },
        [deleteCustomerList.pending]: (state, action) => {
            state.completed = false;
        },
        [deleteCustomerList.fulfilled]: (state, action) => {
            state.completed = true;
            state.errorMessage = null;
        },
        [deleteCustomerList.rejected]: (state, action) => {
            if (state.errorMessage !== null)
                state.errorMessage = [...state.errorMessage, action.error.message];
            else
                state.errorMessage = [action.error.message];
        }
    }
});

const getErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 'CLEANER10':
            return 'Customer already exist';
        default:
            return 'Oops something went wrong, please try again';
    }
}

export const { resetErrorMessage, resetCompleted } = customerSlice.actions
export default customerSlice.reducer